export const ErrorPanelContents = {
  title: "Klaida",
  contents: {
    "Ką daryti?": 'Praneškite svetainės savininkei apie šią žinutę.',
  },
};

// export const ErrorPanelContents = {
//   title: "Error?!?!?!!!!!!",
//   contents: {
//     "what the fuck?!": `I HAVE NO FUCKING CLUE EITHER OMG`,
//     "what do I do now?": `press alt+f4`,
//   },
// };
